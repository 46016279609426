import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";


const ServicesPage = () => {
  return (
    <Layout
      headerStyles="text-4xl font-bold leading-none tracking-widest text-green-600 md:text-5xl lg:text-6xl"
      headerTitle="Services"
      mainLayout=""
    >
      <SEO
        keywords={[
          `bank loan restructuring`,
          `commerical finance solutions`,
          `lender management`,
        ]}
        title="Services"
        description="All growing businesses need capital. Catalina can help."
      />
      <div className="relative py-16 md:py-24 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">

          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">

            <h3>Catalina can provide solutions for a variety of corporate finance needs, including:</h3>
            <ul role="list">
              <li>Virtual CFO services</li>
              <li>Strategic guidance on capital sourcing</li>
              <li>Loan restructuring and refinance</li>
              <li>Lender management</li>
              <li>Modeling and projections</li>
              <li>Acquisition strategy</li>
              <li>KPI creation and oversight</li>
              <li>Independent financial analysis</li>
              <li>and much more</li>
            </ul>

            <p>
              Our clients typically engage us to take a wholistic approach to their corporate finance needs. In this way, Catalina remains a long-term resource for our clients throughout the business cycle without the cost and overhead associated with maintaining a full-time corporate finance team.
            </p>
            <h3>We have deep expertise across business sectors. Here is a sample of industries that we have worked with extensively:</h3>
            <ul>
              <li>Industrial and oilfield manufacturing</li>
              <li>Oilfield services</li>
              <li>Midstream services</li>
              <li>Petrochemical/downstream services</li>
              <li>Railcar leasing, maintenance, and storage</li>
              <li>Equipment rental</li>
              <li>Offshore vessels</li>
              <li>Industrial distribution</li>
              <li>Chemical manufacturing</li>
              <li>Chemical distribution</li>
              <li>Corporate and commercial services</li>
              <li>and many others</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServicesPage;
